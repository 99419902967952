.App {
  text-align: center;
}

.imgReplacement {
  display: none;
}
@media only screen and (max-width: 475px) {
  * {
    color: white;
    background-color: rgb(1,1,90);
    padding: 0% !important;
    margin: 0 !important;
    margin-bottom: 0 !important;
    /*display: block !important;*/
    width: 100% !important;
    border-style: none !important;
    border-radius: 0 !important;
  }

  p {

    padding: 2% !important;
    width: 96% !important;
  }
  body {
    padding: 0 !important;
    margin: 0 !important;
  }

  img {
    display: none !important;
  }

  .imgReplacement {

    display: block !important;
    color: white !important;
  }

  .imgReplacementHeader {
    background-color: rgba(87,85,200,.75) !important;
    margin-top: 5% !important;
    width: 90% !important;
    padding: 5% !important;
    /*border: rgba(87,85,200,1) solid !important;*/
    border-bottom-width: 12px !important;
    border-top-style: none !important;
    border-left-style: none !important;
    border-right-style: none !important;
    border-top-left-radius: 35% !important;
    border-bottom-left-radius: 5% !important;
    border-top-right-radius: 9% !important;
  }

  .imgReplacementParagraph {
    border: rgba(87,85,200,.75) solid !important;
    border-width: 12px !important;
    border-top-style: none !important;
    margin: 4% !important;
    margin-left: auto !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    width: 80% !important;
  }
  button {
    margin-bottom: 10px !important;
    width: 44% !important;
    margin: 3% !important;
  }

  .neverRemove {
    display: block !important;
  }

  li {
    display: list-item !important;
  }
  ol {
    width: 85% !important;
    margin-left: 7.5% !important;
    margin-bottom: 10% !important;
  }

  h4 {
    margin-top: 8% !important;
  }

  .subheader {
    margin: 0 !important;
  }
}